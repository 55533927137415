<template>
  <div class="container">
    <Header>
      <template v-slot:title>
        <span>{{ $t('payment') }}</span>
      </template>
    </Header>
    <div class="main">
      <div class="reservation">
        <div class="list">
          <div class="list-item">
            <van-row type="flex" gutter="8">
              <van-col span="8">
                <van-image height="100" lazy-load fit="cover" :src="reservation.roomType.image" />
              </van-col>
              <van-col span="16">
                <div class="hotel-name">{{ reservation.hotel && reservation.hotel.name || '' }}</div>
                <div class="room-type-name">{{ reservation.roomType && reservation.roomType.name || '' }}</div>
                <div class="breakfast">{{ reservation.arrival }} - {{ reservation.departure }}</div>
              </van-col>
            </van-row>
          </div>
        </div>

        <van-cell-group style="margin: 1em 0;">
          <van-cell :title="$t('totalAmount')" :value="reservation.currency_code + formatNumber(reservation.total_amount)" />
        </van-cell-group>

        <van-collapse v-model="activeName" accordion :border="false" class="van-cell-no-padding">
          <van-collapse-item :title="$t('bookingDetails')" name="1">
            <van-cell-group>
              <van-cell
                v-for="(item, index) of details"
                :key="index"
                :title="item.reservation_date"
                :value="item.rate_amount | formatNumber"
                :get-container="getContainer"
              />
            </van-cell-group>
          </van-collapse-item>
        </van-collapse>

        <van-radio-group v-model="reservation.payment_method" style="margin-top: 10px;">
          <van-cell-group>
            <van-cell
              clickable
              v-for="item of paymentMethods"
              :key="item.id"
              @click="selectPayment(item.code)"
            >
              <template #title>
                <img :src="'/server-static/' + item.icon" />
              </template>
              <template #right-icon>
                <van-radio :name="item.code" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>

      <div style="color: red; padding: 10px 20px;">
        {{ $t('payment notice') }}
      </div>
    </div>

    <div class="footer">
      <van-button type="warning" size="large" style="border-radius: 0; width: 100%;" @click="pay">{{ $t('payNow') }}</van-button>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant'
import Header from '@/components/Header'
import { formatNumber } from '@/filters'
import { getDates } from '@/utils'

export default {
  name: 'Payment',
  components: {
    Header
  },
  data () {
    return {
      activeName: '',
      paymentMethods: [],
      showDetail: false,
      reservation: {
        arrival: '',
        departure: '',
        total_amount: '',
        currency_code: '',
        payment_method: '',
        roomType: {
          name: '',
          image: ''
        },
        hotel: {
          name: ''
        }
      }
    }
  },
  computed: {
    details () {
      const dates = getDates(this.reservation.arrival, this.reservation.departure)
      if (dates.length > 1) {
        dates.pop()
      }
      return dates.map(date => ({
        reservation_date: date,
        rate_amount: this.reservation.rate_amount
      }))
    }
  },
  async mounted () {
    try {
      const response = await this.$axios.get('/payment-method', {
        params: {
          search: 'enable==1',
          sort: 'sequence'
        }
      })
      this.paymentMethods = [response.data.rows[0]]

      const res = await this.$axios.get(`/reservation/${this.$route.params.reservationId}`)
      if (res.data) {
        this.reservation = res.data
        const reservation = this.reservation

        if (!reservation.payment_method === 'NotSelected') {
          this.$router.push({
            name: reservation.payment_method,
            params: { reservationId: reservation.id }
          })
        }
      }
    } catch (e) {
      Notify(e.message)
    }
  },
  methods: {
    formatNumber,
    getContainer () {
      return document.querySelector('.main')
    },
    selectPayment (e) {
      this.reservation.payment_method = e
    },
    async pay () {
      if (this.reservation.payment_method === 'NotSelected') {
        Notify(this.$t('payment method not selected'))
        return
      }

      const res = await this.$axios.patch(`/reservation/${this.reservation.id}/payment-method/${this.reservation.payment_method}`)
      if (res && res.errCode === 0) {
        this.$router.push({
          name: 'OfflinePayment',
          params: { reservationId: this.$route.params.reservationId }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  padding-top: 64px;
  padding-bottom: 50px;
}

.reservation {
  padding: 1rem 1rem 0 1rem;
}

.hotel-name {
  margin-top: .3rem;
  font-size: 1.2rem;
}

.room-type-name {
  margin-top: .3rem;
  font-size: 1rem;
  color: #2766ba;
}

.breakfast {
  color: #5c5c5c;
  margin-top: .3rem;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
}
.van-cell {
  padding-left: 0;
  padding-right: 0;
}
</style>
